import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  Twitter,
  Reddit,
  Instagram,
  Telegram,
  Discord
} from '@icons-pack/react-simple-icons';

const socials = [
  {
    name: 'Twitter',
    href: 'https://twitter.com/MoonDashBSC',
    icon: Twitter,
  },
  // {
  //   name: 'Reddit',
  //   href: 'https://www.reddit.com/r/MoonDashBSC/',
  //   icon: Reddit,
  // },
  {
    name: 'Instagram',
    href: 'https://instagram.com/MoonDashBSC',
    icon: Instagram,
  },
  {
    name: 'Telegram',
    href: 'https://t.me/MoonDashBSC',
    icon: Telegram,
  },
  // {
  //   name: 'Discord',
  //   href: 'https://discord.gg/KxaHpHbQy4',
  //   icon: Discord,
  // },
]



export default function Footer() {
  return (
    <div className="">
      <div className="relative py-16 sm:py-24 lg:py-32 z-20" id="cta">
        <div className="container">
          <div className="bg-gradient-to-tr from-blue-800 to-blue-600 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4 items-center">
            <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
              <div className="lg:self-center">
                <h2 className="text-3xl font-bold text-white sm:text-4xl lg:text-5xl">
                  <span className="block">Buy MoonDash & Join Our Community</span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-blue-200 lg:text-xl">
                  Join the most exciting & rewarding token on the Binance Smart Chain!
                </p>
                <a
                  href="https://poocoin.app/embed-swap?outputCurrency=0x2e44fad7F8C5351C759C68D721a01377E7bf9479" target="_blank"
                  className="mt-8 bg-white border border-transparent rounded-lg shadow px-5 py-3 inline-flex items-center text-base font-medium text-blue-600 hover:bg-blue-50"
                >
                  Buy MoonDash
                </a>
                <div className="flex space-x-5 mt-10">
                  {socials.map((item) => (
                    <a key={item.name} href={item.href} className="text-blue-200 hover:text-white">
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
              <StaticImage
                src="../images/cta.png"
                className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                width={1000}
                alt=""
                objectFit="contain"
              />
            </div>
          </div>
        </div>
      </div>
      <footer className="border-t border-gray-700">
        <div className="py-12 container md:flex md:items-center md:justify-between">
          <div className="flex justify-center space-x-6 md:order-2">
            {socials.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base text-gray-400">&copy; 2021 MoonDash.io - All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>

  )
}